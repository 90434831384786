import React, { createContext, useContext } from 'react';
import useNotifications from '@/hooks/useNotifications';
import { NotifTypes } from '@/type';
import AgbChangedModal from '@/screens/main/components/dialogs/agb-changed';
import TooltipWrrapper from '@/screens/main/components/tooltip/tooltip-wrapper';
import PremiumExpiredModal from '@/screens/main/components/dialogs/premium-expired';
import SubscribeNewslettersModal from '@/screens/main/components/dialogs/subscribe-newsletters';

const NotificationsContext = createContext({});

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotificationsContext must be used within a NotificationsProvider'
    );
  }
  return context;
};

const NotificationsProvider: React.FC = ({ children }) => {
  const { notifications, dialogsCanBeVisible } = useNotifications();

  const agbNotification = notifications.find(
    (notif) => notif.parsedData.type === NotifTypes.AGB
  );
  const premiumNotification = notifications.find(
    (notif) => notif.parsedData.type === NotifTypes.PREMIUM
  );
  const subscribeNotification = notifications.find(
    (notif) => notif.parsedData.type === NotifTypes.SUBSCRIBE_NEWSLETTERS
  );

  return (
    <NotificationsContext.Provider value={{}}>
      {children}
      <TooltipWrrapper>
        {dialogsCanBeVisible && agbNotification && !premiumNotification && (
          <AgbChangedModal agbChangedNotificationId={agbNotification.id} />
        )}
        {dialogsCanBeVisible && premiumNotification && (
          <PremiumExpiredModal
            premiumExpiredNotificationId={premiumNotification.id}
          />
        )}
        {dialogsCanBeVisible &&
          !agbNotification &&
          !premiumNotification &&
          subscribeNotification && (
            <SubscribeNewslettersModal
              notificationId={subscribeNotification.id}
            />
          )}
      </TooltipWrrapper>
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
