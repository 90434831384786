import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import {
  useClassRoomDetailsQuery,
  ClassRoomsOngoingDocument,
  ClassRoomLearningSessionStatus,
  ClassRoomSessionDocument,
  SpecialityGroupListDocument,
  useDailyTipperDataLazyQuery,
  useSpecialityGroupLazyQuery,
  useDailyTipperDataQuery,
  useClassRoomSettingsQuery,
  useJoinNetworkClassRoomMutation,
  useJoinBlendedClassRoomMutation,
  useJoinLekClassRoomMutation,
  useUserSubscriptionQuery,
} from '@/graphql';
import { Skeleton } from '@material-ui/lab';
import { Box, Paper, Typography, Link } from '@material-ui/core';
import ClassRoomHeader from '../../header';
import ClassRoomDescription from '../../description';
import Lectures from '../../lectures';
import StackLayout from '../../../components/stack-layout';
import AppButton from '@/components/app-button';
import EmptyState from '@/screens/deep-links/empty-state';
import { useUser } from '@/contexts/user-context';
import {
  ClassroomTypes,
  CustomErrorTypes,
  DuelErrorTypes,
  SubscriptionStatus,
  TabNavIdsEnum,
} from '@/type';
import {
  useNeedPremiumModal,
  useSubscriptionInProgressModal,
} from '@/hooks/useModal';
import { useStyles } from './styles';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { LS_KEY_TABINDEX, MAIN_ORGANIZER_NAME } from '@/utils/constants';
import { AppTab, TabPanel, AppTabs } from '@/components/tabs';
import { useTabsNav } from '@/hooks/useTabsNav';
import MembersTab from '../classroom-single/members-tab';
import { getSubscriptionsPath } from '@/utils/helpers';
import { useSnackbarContext } from '@/contexts/snackbar-context';

type ClassRoomParams = {
  sessionId: string;
  specialityGroupId?: string;
};

const ClassRoomDetails: React.FC = () => {
  const { t } = useTranslation();
  const { cache } = useApolloClient();
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUser();
  const { isUserPremium } = useGetPremiumData();
  const params = new URLSearchParams(history.location.search);
  const deepLinkHash = params.get('hash');

  const { data: subsData } = useUserSubscriptionQuery();
  const needPremiumModal = useNeedPremiumModal(() => goToPremium());
  const subscriptionInProgressModal = useSubscriptionInProgressModal();
  const { setCustomSnack } = useSnackbarContext();

  const { sessionId, specialityGroupId } = useParams<ClassRoomParams>();

  const { value, handleChange } = useTabsNav(
    `${TabNavIdsEnum.CLASSROOM}${sessionId}`
  );

  const { data, loading, error } = useClassRoomDetailsQuery({
    variables: { sessionId },
    fetchPolicy: 'cache-and-network',
  });

  const { data: settingsData } = useClassRoomSettingsQuery({
    variables: {
      learningSessionId: sessionId,
      userSessionSettingId: '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const [getSpecialityGroup, { data: dataSpecialityGroup }] =
    useSpecialityGroupLazyQuery({
      variables: {
        specialityGroupId,
      },
    });
  const specialityGroupIcon =
    dataSpecialityGroup?.specialityGroup?.icon ||
    data?.classRoomLearningSession?.specialityGroupIcon;

  useEffect(() => {
    if (specialityGroupId) getSpecialityGroup();
  }, [getSpecialityGroup, specialityGroupId]);

  const isForPremiumOnly = data?.classRoomLearningSession?.isForPremiumOnly;
  const isForSingle = data?.classRoomLearningSession?.isForSingle;
  const isNetworkType =
    data?.classRoomLearningSession?.classRoom.enrollmentType ===
    ClassroomTypes.NETWORK;

  const [joinNetworkCR, { loading: joinNetworkCRLoading }] =
    useJoinNetworkClassRoomMutation();
  const [joinBlendedCR, { loading: joinBlendedCRLoading }] =
    useJoinBlendedClassRoomMutation();
  const [joinLekCR, { loading: joinLekCRLoading }] =
    useJoinLekClassRoomMutation();

  const [refetchDailyTipperData] = useDailyTipperDataLazyQuery({
    fetchPolicy: 'network-only',
  });

  const dtData = useDailyTipperDataQuery();
  const err = JSON.stringify(dtData.error?.networkError);
  const message = err && JSON.parse(err)?.result?.errors[0]?.message;

  useEffect(() => {
    const isCRFinished =
      data?.classRoomLearningSession?.status ===
        ClassRoomLearningSessionStatus.Finished ||
      settingsData?.userClassRoomSettings?.isClosed;
    if (
      isForSingle &&
      ((settingsData?.userClassRoomSettings?.created &&
        !settingsData?.userClassRoomSettings?.isOut) ||
        isCRFinished)
    ) {
      localStorage.setItem(
        `${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`,
        isCRFinished ? '1' : '0'
      );
      const settingsId = settingsData?.userClassRoomSettings?.id;
      history.replace(
        isCRFinished && settingsId
          ? `/classroom-finished/${sessionId}/${settingsId}`
          : `/classroom/${sessionId}`,
        {
          fromCongratulation: true,
        }
      );
    }
  });

  const joinCallback = useCallback(
    (id: string) => {
      cache.modify({
        id: `UserType:${user?.id}`,
        fields: {
          classRoomLearningSession(cachedSessions) {
            const sessions = cachedSessions.length ? [...cachedSessions] : [];
            return sessions.push({ id });
          },
        },
      });
      if (message === DuelErrorTypes.ERROR_8) refetchDailyTipperData();
      if (id) {
        localStorage.setItem(
          `${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`,
          '0'
        );
        history.replace(`/classroom/${id}`, {
          fromCongratulation: true,
          showSnackCrJoined: true,
        });
      }
    },
    [cache, message, refetchDailyTipperData, history, user]
  );

  const handleJoinClassroom = useCallback(async () => {
    if (isNetworkType && isForPremiumOnly && !isUserPremium) {
      subsData?.me?.customer?.subscription?.status ===
      SubscriptionStatus.INCOMPLETE
        ? subscriptionInProgressModal.open()
        : needPremiumModal.open();
    } else {
      try {
        if (isNetworkType) {
          const resFromNetworkCR = await joinNetworkCR({
            variables: {
              sessionId,
              specialityGroupIcon: specialityGroupIcon,
              deepLinkHash: deepLinkHash,
            },
            refetchQueries: [
              { query: ClassRoomsOngoingDocument },
              { query: SpecialityGroupListDocument },
              {
                query: ClassRoomSessionDocument,
                variables: { sessionId },
              },
            ],
          });
          joinCallback(
            resFromNetworkCR.data?.joinToNetworkClassRoom?.classRoomSession?.id
          );
        } else if (
          data?.classRoomLearningSession?.classRoom.enrollmentType ===
          ClassroomTypes.BLENDED
        ) {
          const res = await joinBlendedCR({
            variables: {
              sessionId,
              deepLinkHash: deepLinkHash,
            },
            refetchQueries: [
              { query: ClassRoomsOngoingDocument },
              {
                query: ClassRoomSessionDocument,
                variables: { sessionId },
              },
            ],
          });
          joinCallback(res.data?.joinToBlendedClassRoom?.classRoomSession?.id);
        } else if (
          data?.classRoomLearningSession?.classRoom.enrollmentType ===
          ClassroomTypes.LEK
        ) {
          const res = await joinLekCR({
            variables: {
              sessionId,
              deepLinkHash: deepLinkHash,
            },
            refetchQueries: [
              { query: ClassRoomsOngoingDocument },
              {
                query: ClassRoomSessionDocument,
                variables: { sessionId },
              },
            ],
          });
          joinCallback(res.data?.joinToLekClassRoom?.classRoomSession?.id);
        }
      } catch (error) {
        const message =
          error?.networkError?.result?.errors[0]?.message || error?.message;
        setCustomSnack({
          visibility: true,
          message:
            message === CustomErrorTypes.ERROR_NO_SEATS
              ? 'classrooms.snackBars.message-cr-no-seats'
              : 'fallback.message.title',
          durationInMs: 10000,
        });
        console.error('useJoinClassRoomMutation', error);
      }
    }
  }, [
    joinNetworkCR,
    joinBlendedCR,
    joinLekCR,
    joinCallback,
    sessionId,
    isNetworkType,
    isUserPremium,
    isForPremiumOnly,
    specialityGroupIcon,
    deepLinkHash,
    data,
    needPremiumModal,
    subscriptionInProgressModal,
    subsData,
    setCustomSnack,
  ]);

  const goToPremium = () => {
    const historyState: any = history.location.state;
    needPremiumModal.close();
    history.replace(getSubscriptionsPath(), {
      prevPage: `/classroom-details/${sessionId}`,
      rootPageForBack: historyState?.rootPageForBack || '/biblio',
      sessionId,
    });
  };

  const classroom = useMemo(
    () => data?.classRoomLearningSession?.classRoom,
    [data]
  );
  const organizer = useMemo(() => classroom?.organizer, [classroom]);

  const backLink = useMemo(() => {
    const historyState: any = history.location.state;
    if (historyState?.rootPageForBack === '/home') return '/home';
    return historyState?.fromCongratulation ? '/biblio' : undefined;
  }, [history]);

  const pageTitle = useMemo(() => {
    switch (data?.classRoomLearningSession?.classRoom.enrollmentType) {
      case 1:
        return t('classrooms.single-page-title-blended');
      case 2:
        return t('classrooms.single-page-title-network');
      case 3:
        return t('classrooms.single-page-title-lek');
      default:
        return '';
    }
  }, [data, t]);

  if (error?.message) return <EmptyState />;

  return (
    <StackLayout title={pageTitle} back={backLink}>
      <ClassRoomHeader
        sessionId={sessionId}
        image={
          (organizer?.name === MAIN_ORGANIZER_NAME &&
            classroom?.backgroundImage) ||
          classroom?.classRoomImage
        }
        specialityGroupIcon={
          organizer?.name === MAIN_ORGANIZER_NAME ? specialityGroupIcon : null
        }
        startDate={data?.classRoomLearningSession?.startDate}
        finishDate={data?.classRoomLearningSession?.finishDate}
        title={classroom?.title}
        organizerName={organizer?.name}
        organizerId={organizer?.id}
        certificate={classroom?.certificate?.points}
        isForPremiumOnly={data?.classRoomLearningSession?.isForPremiumOnly}
        duration={data?.classRoomLearningSession?.duration}
        classroomType={classroom?.enrollmentType}
        deepLinkHash={data?.classRoomLearningSession?.deepLink}
      />
      <div className={classes.content}>
        <AppTabs
          style={{ position: 'sticky', top: 0, zIndex: 100 }}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <AppTab
            id="classroom-details-tab"
            data-cy-classrooms-caption-details
            label={t('classrooms.details')}
          />
          <AppTab
            id="classroom-attendees-tab"
            data-cy-classrooms-caption-attendees
            label={t('classrooms.attendees')}
          />
        </AppTabs>
        <TabPanel value={value} index={0}>
          {loading ? (
            <Paper>
              <Box py={5} px={6}>
                {[...Array(15)].map((i, idx) => (
                  <Skeleton key={idx} height={30} />
                ))}
              </Box>
            </Paper>
          ) : (
            <>
              <ClassRoomDescription
                description={
                  data?.classRoomLearningSession?.classRoom?.description
                }
                link={
                  data?.classRoomLearningSession?.classRoom
                    ?.learningMoreInfoLink
                }
                orgId={organizer?.id}
                orgName={organizer?.name}
                orgEmail={organizer?.email}
                orgLogo={organizer?.logo}
                orgWebsite={organizer?.linkToSite}
                sponsors={data?.classRoomLearningSession?.classRoom.sponsors}
              >
                <Lectures sessionId={data?.classRoomLearningSession?.id} />
                {data?.classRoomLearningSession?.status !==
                  ClassRoomLearningSessionStatus.Finished && (
                  <Box px={2}>
                    <AppButton
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={handleJoinClassroom}
                      disabled={
                        joinNetworkCRLoading ||
                        joinBlendedCRLoading ||
                        joinLekCRLoading
                      }
                      loading={
                        joinNetworkCRLoading ||
                        joinBlendedCRLoading ||
                        joinLekCRLoading
                      }
                    >
                      {t('classrooms.join-link2')}
                    </AppButton>
                    {!!classroom?.isExternalOrganizer && (
                      <Box mt={2}>
                        <Typography variant="caption">
                          {t('classrooms.external-organizer.message')}
                          <Link
                            variant="caption"
                            color="primary"
                            to="/privacy-dataprotection"
                            component={RouterLink}
                          >
                            {t('classrooms.external-organizer.privacy')}
                          </Link>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </ClassRoomDescription>
            </>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MembersTab
            sessionId={sessionId}
            crTitle={data?.classRoomLearningSession?.classRoom.title}
            roomFinished={false}
            roomInProgress={
              data?.classRoomLearningSession?.status ===
              ClassRoomLearningSessionStatus.InProgress
            }
            isDuelsLimit={true}
            isNetworkType={isNetworkType}
            isDuelsOff={true}
            deepLinkHash={data?.classRoomLearningSession?.deepLink}
            handleClickOnPlayBtn={() => false}
          />
        </TabPanel>
      </div>
    </StackLayout>
  );
};

export default ClassRoomDetails;
